/***** Always True *****/
@media only screen {
    /*** General Purpose Display Styles ***/
    .none {display: none;}
    .flex {display: flex;}
    .fixed {position: fixed;}
    .hidden {visibility: hidden;}
    .relative {position: relative;}
    .absolute {position: absolute;}
    .column {flex-direction: column;}
    .align-end {align-items: flex-end;}
    .align-center {align-items: center;}
    .align-self-end {align-self: flex-end;}
    .align-start {align-items: flex-start;}
    .align-self-center {align-self: center;}
    .justify-end {justify-content: flex-end;}
    .justify-center {justify-content: center;}
    .align-self-start {align-self: flex-start;}
    .row-reverse {flex-direction: row-reverse;}
    .justify-start {justify-content: flex-start;}
    .justify-around {justify-content: space-around;}
    .column-reverse {flex-direction: column-reverse;}
    .justify-between {justify-content: space-between;}
    
    .scroll {overflow: scroll;}
    .no-scrollbar::-webkit-scrollbar {display: none;}
    .no-scrollbar {-ms-overflow-style: none;scrollbar-width: none;}
    
    .pointer:hover {cursor: pointer;}
    
    /*** Widths ***/
    .width-100 {width: 100%;}
    .width-fit {width: fit-content;}
    .width-max {width: max-content;}
    
    /*** Heights ***/
    .height-100 {height: 100%;}
    .height-fit {height: fit-content;}
    .height-max {height: max-content;}
    
    /*** Shapes ***/
    .circle {border-radius: 50%;}
    .rectangle {border-radius: 0%;}
    
    /*** Paddings ***/
    .padding-0 {padding: 0;}
    
    /*** Margins ***/
    .margin-0 {margin: 0;}
    
    /*** Colors ***/
    .black {color: #000;}
    .white {color: #fff;}
    
    /*** Opacities ***/
    
    /*** Fonts ***/
    
    /*** Text Styles ***/
    .text-start {text-align: start;}
    .text-center {text-align: center;}
    .word-break {word-wrap: break-word;}

    /*** Background Color Gradients ***/
    .green  {background: linear-gradient(180deg, #39CBB9 0%, #5BFE9E 100%);}
    .yellow {background: linear-gradient(225deg, #FBFF3C 0%, #DCD531 88.84%)}
    .red    {background: linear-gradient(225deg, #F78888 0%, #DA6767 88.84%);}
    .blue   {background: linear-gradient(225deg, #91FFFF 0%, #87E9FF 88.84%);}
    .orange {background: linear-gradient(225deg, #D69334 0%, #FD8F3C 88.84%);}
    .purple {background: linear-gradient(269.15deg, #C183F2 4.11%, #E5B2FF 96.97%);}

    /*** Shadows ***/
    
    /*** Rotations ***/
    
    /*** Squares ***/

    /*** Element Styles ***/
    
    /*** Class Styles ***/
    .select-section {
        color: #121212 !important;
        background-color: #fff !important;
    }

    .modal {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /*** Mui Overridden ***/

    /*** Animations ***/
    
}

/***** > 1000px *****/
@media only screen and (min-width: 1000px) {
    
}

/***** <= 1000px *****/
@media only screen and (max-width: 1000px) {
    
}